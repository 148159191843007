<template>
  <div class="role">
    <el-button
      type="success"
      @click="handleChangeAdd"
      style="margin-bottom: 20px"
    >
      新增
    </el-button>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #action>
        <el-table-column label="操作" width="380" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="handleChangeEdit(scoped.row)"
            >
              编辑
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleChangePermissions(scoped.row)"
            >
              权限设置
            </el-button>
            <el-button
              type="primary"
              size="small"
              v-if="scoped.row.userTotal === 0"
              @click="handleChangeDelete(scoped.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="rolevisible"
      :title="roleTitle"
      width="500"
      :before-close="roleClose"
    >
      <el-form
        ref="roleFormRef"
        :model="roleForm"
        :rules="roleFormRules"
        label-width="80px"
        class="roleform"
      >
        <el-form-item label="名称：" prop="nickname">
          <el-input
            v-model="roleForm.nickname"
            placeholder="请输入"
            :maxLength="10"
            clearable
          />
        </el-form-item>
        <el-form-item label="备注：">
          <el-input
            v-model="roleForm.remark"
            placeholder="请输入"
            type="textarea"
            :rows="4"
            show-word-limit
            maxLength="200"
          />
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input
            v-model="roleForm.sort"
            @keyup="number"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="状态：" prop="state">
          <el-radio-group v-model="roleForm.state">
            <el-radio
              v-for="(item, i) in stateList"
              :key="i"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="roleSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="permissionsvisible"
      title="权限设置"
      width="500"
      :before-close="permissionsClose"
      class="permissions"
    >
      <div class="top">
        <span class="label">权限</span>
        <el-checkbox
          v-model="showAll"
          label="展开/折叠"
          size="large"
          @change="setCheckedShow"
        />
        <el-checkbox
          v-model="checkAll"
          label="全选/全不选"
          size="large"
          @change="setCheckedKeys"
        />
      </div>
      <el-tree
        ref="treeRef"
        :data="treeData"
        :props="treeProps"
        show-checkbox
        :default-checked-keys="menuIds"
        node-key="id"
        @check="handleCheckChangeTree"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="roleTreeSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs, ref, nextTick, watch } from 'vue'
import TableView from '@/components/TableView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  rolePage,
  roleDelete,
  roleUpdate,
  roleCreate,
  roleMenu,
  roleResources
} from '@/api/permissionManagement/role'
import { menuPage } from '@/api/permissionManagement/menu'
const roleFormRef = ref()
const treeRef = ref()
const initData = reactive({
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    { prop: 'id', label: 'ID' },
    { prop: 'name', label: '名称' },
    { prop: 'remark', label: '备注' },
    { prop: 'sort', label: '排序' },
    { prop: 'createTime', label: '创建时间' },
    { slot: 'action' }
  ],
  rolevisible: false,
  roleTitle: '',
  roleForm: {
    id: '',
    nickname: '',
    remark: '',
    sort: '',
    state: 0
  },
  roleFormRules: {
    nickname: [
      {
        required: true,
        message: '请输入名称',
        trigger: 'blur'
      }
    ],
    sort: [
      {
        required: true,
        message: '请输入排序',
        trigger: 'blur'
      }
    ],
    state: [
      {
        required: true,
        message: '请选择状态',
        trigger: 'change'
      }
    ]
  },
  stateList: [
    {
      label: '正常',
      value: 0
    },
    {
      label: '停用',
      value: 1
    }
  ],
  permissionsvisible: false,
  treeData: [],
  showAll: false,
  checkAll: false,
  checkedKeysList: [],
  menuIds: [],
  initKeys: []
})
const treeProps = {
  children: 'children',
  label: 'name'
}
const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  rolevisible,
  roleTitle,
  roleForm,
  roleFormRules,
  permissionsvisible,
  treeData,
  showAll,
  checkAll,
  stateList,
  checkedKeysList,
  menuIds,
  initKeys
} = toRefs(initData)

onMounted(() => {
  getDataList()
})

// 表单提交
const roleSubmit = () => {
  if (!roleFormRef.value) return
  roleFormRef.value.validate(valid => {
    if (valid) {
      const data = {
        name: roleForm.value.nickname,
        sort: roleForm.value.sort,
        roleStatus: roleForm.value.state
      }
      if (roleForm.value.remark != '') {
        data.remark = roleForm.value.remark
      }
      if (roleForm.value.id == '') {
        roleCreate(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            roleClose()
            getDataList()
          } else {
            ElMessage.error(res.msg)
          }
        })
      } else {
        data.id = roleForm.value.id
        roleUpdate(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            roleClose()
            getDataList()
          } else {
            ElMessage.error(res.msg)
          }
        })
      }
    } else {
      console.log('error submit!')
      return false
    }
  })
}

const number = e => {
  e.target.value = e.target.value.replace(/[^\d]/g, '')
}

// 关闭
const roleClose = () => {
  roleForm.value = {
    id: '',
    nickname: '',
    remark: '',
    sort: '',
    state: 0
  }
  roleFormRef.value.resetFields()
  rolevisible.value = false
}

// 新增
const handleChangeAdd = () => {
  roleTitle.value = '新增角色'
  rolevisible.value = true
}
// 编辑
const handleChangeEdit = row => {
  roleForm.value.id = row.id
  roleForm.value.nickname = row.name
  roleForm.value.remark = row.remark
  roleForm.value.state = row.roleStatus
  roleForm.value.sort = row.sort
  roleTitle.value = '编辑角色'
  rolevisible.value = true
}

// 权限设置
const handleChangePermissions = row => {
  roleForm.value.id = row.id
  initKeys.value = []
  getMenuDataList()
  setTimeout(() => {
    getRoleResources(row)
  }, 100)
  permissionsvisible.value = true
}
// 权限选择
const handleCheckChangeTree = (data1, data2) => {
  const checkedKeys = data2.checkedKeys
  const halfCheckedKeys = data2.halfCheckedKeys
  checkedKeysList.value = [...halfCheckedKeys, ...checkedKeys]
}

// 权限设置提交
const roleTreeSubmit = () => {
  if (checkedKeysList.value.length > 0) {
    const data = {
      menuIds: checkedKeysList.value,
      roleId: roleForm.value.id
    }
    roleMenu(data).then(({ data: res }) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        permissionsvisible.value = false
        getDataList()
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    ElMessage.error('请设置权限')
  }
}
// 权限关闭
const permissionsClose = () => {
  showAll.value = false
  checkAll.value = false
  treeRef.value.setCheckedNodes([])
  for (var i = 0; i < treeRef.value.store._getAllNodes().length; i++) {
    treeRef.value.store._getAllNodes()[i].expanded = false
  }
  permissionsvisible.value = false
}
// 全选/全不选
const setCheckedKeys = val => {
  treeRef.value.setCheckedNodes(val ? treeData.value : [])
  if (val) {
    checkedKeysList.value = treeRef.value?.getCheckedKeys()
  } else {
    checkedKeysList.value = []
  }
  // checkedKeysList
}
// 展开/折叠
const setCheckedShow = val => {
  for (var i = 0; i < treeRef.value.store._getAllNodes().length; i++) {
    treeRef.value.store._getAllNodes()[i].expanded = val
  }
}
// 删除
const handleChangeDelete = row => {
  ElMessageBox.confirm('删除后该角色无法恢复，是否确认?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      roleDelete({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  rolePage(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 获取列表数据
const getMenuDataList = () => {
  menuPage().then(({ data: res }) => {
    if (res.code == 200) {
      treeData.value = res.data
    } else {
      ElMessage.error(res.msg)
      treeData.value = []
    }
  })
}

// 获取列表数据
const getRoleResources = row => {
  roleResources({ id: row.id }).then(({ data: res }) => {
    if (res.code == 200) {
      nextTick(() => {
        const arr = []
        res.data.forEach(item => {
          if (
            !treeRef.value?.getNode(item).childNodes ||
            !treeRef.value?.getNode(item).childNodes.length
          ) {
            arr.push(item)
          }
        })
        menuIds.value = arr
        treeRef.value?.setCheckedKeys(arr)
        checkedKeysList.value = arr
        initTreeData(treeData.value)
        checkAll.value = arr.length == initKeys.value.length
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const initTreeData = tree => {
  tree.forEach(item => {
    if (item.children) {
      initTreeData(item.children)
    } else {
      initKeys.value.push(item.id)
    }
  })
}
</script>
<style lang="scss">
.permissions {
  .el-dialog__body {
    padding-top: 0;
  }
  .top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .label {
      margin-right: 10px;
    }
  }
  .el-tree {
    height: 450px;
    margin-left: 45px;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
