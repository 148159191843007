import request from '../Axios'

// 列表
export const menuPage = data => {
  return request({
    url: '/menu/list',
    method: 'get',
    data
  })
}

// 详情
export const menuSimple = params => {
  return request({
    url: `/menu/list-all-simple`,
    method: 'get'
  })
}

// 详情
export const menuGet = params => {
  return request({
    url: `/menu/get?id=${params.id}`,
    method: 'get'
  })
}

// 删除角色
export const menuDelete = params => {
  return request({
    url: `/menu/delete?id=${params.id}`,
    method: 'delete'
  })
}


// 修改角色
export const menuUpdate = data => {
  return request({
    url: `/menu/update`,
    method: 'put',
    data
  })
}

// 创建角色
export const menuCreate = data => {
  return request({
    url: '/menu/create',
    method: 'post',
    data
  })
}
